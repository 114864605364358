import { APP_INITIALIZER, ErrorHandler, inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { BackgroundRequestEffects } from './background-request/background-request.effects';
import { BackgroundRequestModule } from './background-request/background-request.module';
import { metaReducers, reducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AuthModule } from './auth/auth.module';
import { SettingsModule } from './settings/settings.module';
import { SharedModule } from './shared/shared.module';
import { UserBucketModule } from './user-bucket/user-bucket.module';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { ApiModule } from './api/api.module';
import * as Sentry from '@sentry/angular';
import { SentryErrorHandler } from './shared/sentry.service';
import { VmsModule } from './vms/vms.module';
import { ActivityLogModule } from './activity-log/activity-log.module';
import { ActivityLogEffects } from './activity-log/activity-log.effects';
import { HomeModule } from './home/home.module';
import { TripEffects } from './trip/trip.effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SettingsEffects } from './settings/settings.effects';
import { TripModule } from './trip/trip.module';
import { WeightNoticeModule } from './weight-notice/weight-notice.module';
import { RetainNoticeModule } from './retain-notice/retain-notice.module';
import { PriorEmergencyNoticeModule } from './prior-emergency-notice/prior-emergency-notice.module';
import { CatchDisposalRecordModule } from './catch-disposal-record/catch-disposal-record.module';
import { LogbooksModule } from './logbooks/logbooks.module';
import { LogbookDaysModule } from './logbook-days/logbook-days.module';
import { LogbookEventsModule } from './logbook-events/logbook-events.module';
import { LogbookCatchesModule } from './logbook-catches/logbook-catches.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { NotificationModule } from './notification/notification.module';
import { NotificationEffects } from './notification/notification.effects';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { LiveUpdateModalComponent } from './live-update-modal/live-update-modal.component';
import { CatchDisposalRecordCatchesModule } from './catch-disposal-record-catches/catch-disposal-record-catches.module';
import { ManualPollingModule } from './manual-polling/manual-polling.module';
import { PreferenceDisposalModule } from './preference-disposal/preference-disposal.module';
import { PreferenceConsignmentModule } from './preference-consignment/preference-consignment.module';
import { StorageSyncEffects } from './reducers/ngrx-ionic-storage-sync.effects';
import { AppStateModule } from './app-state/app-state.module';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ContentfulModule } from './contentful/contentful.module';
import { ContentfulEffects } from './contentful/contentful.effects';
import { PendingTransactionModalModule } from './pending-transaction-modal/pending-transaction-modal.module';
import { PreferenceLandingPagesModule } from './preference-landing-places/preference-landing-places.module';
import { PreferenceCDRFishFormsMeasuresModule } from './preference-cdr-fish-forms-measures/preference-cdr-fish-forms-measures.module';
import { PreferenceRegionsSpeciesModule } from './preferences-regions-species/preferences-regions-species.module';
import { VersionMismatchModule } from './version-mismatch-page/version-mismatch-page.module';
import { LogbookSummaryModule } from './logbook-summary/logbook-summary.module';
import { PreferencesGPSModule } from './preference-gps/preference-gps.module';
import { PreferenceModule } from './preference/preference.module';
import { PreferenceEffects } from './preference/preference.effects';
import { AuthService } from './auth/auth.service';
import { TokenInterceptor } from './auth/token.interceptor';
import { ExpiredTokenInterceptor } from './auth/expired-token.interceptor';

if (['prod', 'uat'].includes(environment.targetEnv)) {
  Sentry.init({
    dsn: 'https://fed93745ae5045d6afa435db33efa4e0@o264253.ingest.sentry.io/5686411',
    maxValueLength: Infinity,
    environment: environment.targetEnv,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    release: environment.version,
  });
}

@NgModule({
  declarations: [AppComponent, SideMenuComponent, LiveUpdateModalComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'ios', innerHTMLTemplatesEnabled: true }),
    AppRoutingModule,
    VersionMismatchModule,
    AuthModule,
    UserBucketModule,
    VmsModule,
    ActivityLogModule,
    HomeModule,
    TripModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      StorageSyncEffects,
      ActivityLogEffects,
      BackgroundRequestEffects,
      TripEffects,
      SettingsEffects,
      ContentfulEffects,
      NotificationEffects,
      PreferenceEffects,
    ]),
    ApiModule,
    SettingsModule,
    SharedModule,
    LogbooksModule,
    LogbookDaysModule,
    LogbookEventsModule,
    LogbookCatchesModule,
    LogbookSummaryModule,
    BackgroundRequestModule,
    WeightNoticeModule,
    RetainNoticeModule,
    PriorEmergencyNoticeModule,
    CatchDisposalRecordModule,
    ContentfulModule,
    NotificationModule,
    AppStateModule,
    ManualPollingModule,
    PreferenceModule,
    PreferencesGPSModule,
    PreferenceDisposalModule,
    PreferenceLandingPagesModule,
    PreferenceConsignmentModule,
    PreferenceRegionsSpeciesModule,
    PreferenceCDRFishFormsMeasuresModule,
    PendingTransactionModalModule,
    IonicStorageModule.forRoot({
      driverOrder: [
        CordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    }),
    CatchDisposalRecordCatchesModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    Deploy,
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const authService = inject(AuthService);
        return () => authService.setup();
      },
      multi: true,
    },
    provideHttpClient(
      withInterceptors([TokenInterceptor, ExpiredTokenInterceptor])
    ),
  ],
})
export class AppModule {}
