import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { equals, isEmpty, isNilOrEmpty, propEq } from '@qld-recreational/ramda';
import { includes, isNil } from 'rambda';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import {
  Logbook,
  LogbookClass,
  LogbookSpecies,
  QuotaRegion,
} from '../api/model';
import {
  ILogbookDaysState,
  selectLogbookDayById,
} from '../logbook-days/logbook-days.reducer';
import {
  ILogbooksState,
  selectLogbookById,
} from '../logbooks/logbooks.reducer';
import {
  IPreferenceRegionsSpeciesForLogbookState,
  IPreferenceRegionsSpeciesState,
  selectFavoureRegionsAndSpeciesByLogbook,
} from '../preferences-regions-species/preferences-regions-species.reducer';
import {
  IUserBucketState,
  selectLogbookReferenceByFisherySymbol,
  selectReferenceData,
  selectSecondaryLogbookReferenceByClassAndFisherySymbol,
} from '../user-bucket/user-bucket.reducer';
import { CatchPageSpecies, ILogbookEvent } from './logbook-events.reducer';

interface IEventRegionsAndSpecies {
  [region: string]: CatchPageSpecies[];
}

type QuotaSymbolRegionMap = Map<string, string>;

@Injectable()
export class LogbookEventsService {
  constructor(
    private logbookDaysStore: Store<ILogbookDaysState>,
    private logbooksStore: Store<ILogbooksState>,
    private userBucketStore: Store<IUserBucketState>,
    private preferenceRegionsSpeciesStore: Store<IPreferenceRegionsSpeciesState>
  ) {}

  public includesPendingSpecies(
    speciesToFind: CatchPageSpecies,
    pendingSpecies: CatchPageSpecies[]
  ) {
    return pendingSpecies.find(
      (species) =>
        equals(species.id, speciesToFind.id) &&
        (isNilOrEmpty(species.quotaSymbol)
          ? isNilOrEmpty(speciesToFind.quotaSymbol)
          : equals(species.quotaSymbol, speciesToFind.quotaSymbol))
    );
  }

  public getLogbooksByEvent(
    logbookEvent: ILogbookEvent
  ): Observable<[Logbook, Logbook]> {
    return this.logbookDaysStore
      .select(selectLogbookDayById(logbookEvent.logbookDayId))
      .pipe(
        take(1),
        switchMap((logbookDay) =>
          this.logbooksStore
            .select(selectLogbookById(logbookDay.logbookId))
            .pipe(take(1))
        ),
        switchMap(({ fisherySymbol }) =>
          combineLatest([
            this.userBucketStore.select(
              selectLogbookReferenceByFisherySymbol(fisherySymbol)
            ),
            this.userBucketStore.select(
              selectSecondaryLogbookReferenceByClassAndFisherySymbol(
                LogbookClass.SR,
                fisherySymbol
              )
            ),
          ]).pipe(take(1))
        )
      );
  }

  public getQuotaSymbolRegionMap(): Observable<QuotaSymbolRegionMap> {
    return this.userBucketStore.select(selectReferenceData).pipe(
      take(1),
      map(
        (referenceData) =>
          new Map(
            referenceData.quota?.map((quota) => [
              quota.quotaSymbol,
              quota.quotaRegion || QuotaRegion.NonQuota,
            ])
          )
      )
    );
  }

  private speciesExistsInMap(
    species: CatchPageSpecies,
    regionSpeciesMap: IEventRegionsAndSpecies
  ): boolean {
    return Object.entries(regionSpeciesMap).some(([, speciesList]) =>
      includes(species, speciesList)
    );
  }

  private applyPreferences(
    logbook: Logbook,
    quotaSymbolRegionMap: QuotaSymbolRegionMap,
    preferredRegionsAndSpecies: IPreferenceRegionsSpeciesForLogbookState
  ): IEventRegionsAndSpecies {
    const regionSpeciesMap: IEventRegionsAndSpecies = {
      [QuotaRegion.NonQuota]: [],
    };

    // Add all the preferred regions
    Object.keys(preferredRegionsAndSpecies.quotaSpecies)?.forEach((region) => {
      regionSpeciesMap[region] = [];
    });
    // add all the preferred species (even if they don't exist in the above regions)
    logbook.species?.forEach((species) => {
      if (isNil(species.quotaSymbols)) {
        if (includes(species.id, preferredRegionsAndSpecies.nonquotaSpecies)) {
          regionSpeciesMap[QuotaRegion.NonQuota].push({
            ...species,
            quotaSymbol: '',
          });
        }
      } else {
        species.quotaSymbols?.forEach((quotaSymbol) => {
          const region = quotaSymbolRegionMap.get(quotaSymbol);
          const speciesWithQuotaSymbol = { ...species, quotaSymbol };

          if (
            includes(
              species.id,
              preferredRegionsAndSpecies.quotaSpecies[region] ?? []
            )
          ) {
            if (isNil(regionSpeciesMap[region])) {
              regionSpeciesMap[region] = [speciesWithQuotaSymbol];
            } else {
              regionSpeciesMap[region].push(speciesWithQuotaSymbol);
            }
          }
        });
      }
    });
    return regionSpeciesMap;
  }

  /**
   * Note: Mutates "regionSpeciesMap"
   */
  private addAllSpeciesFromAllRegions(
    regionSpeciesMap: IEventRegionsAndSpecies,
    logbookSpeciesList: LogbookSpecies[],
    quotaSymbolRegionMap: QuotaSymbolRegionMap
  ): void {
    logbookSpeciesList?.forEach((species) => {
      if (!species.primary) {
        return;
      }

      if (isNil(species.quotaSymbols)) {
        regionSpeciesMap[QuotaRegion.NonQuota].push({
          ...species,
          quotaSymbol: '',
        });
      } else {
        species.quotaSymbols?.forEach((quotaSymbol) => {
          const speciesRegion = quotaSymbolRegionMap.get(quotaSymbol);
          const speciesWithQuotaSymbol = { ...species, quotaSymbol };

          if (isNil(speciesRegion)) {
            return;
          }

          if (isNil(regionSpeciesMap[speciesRegion])) {
            regionSpeciesMap[speciesRegion] = [speciesWithQuotaSymbol];
          } else {
            regionSpeciesMap[speciesRegion].push(speciesWithQuotaSymbol);
          }
        });
      }
    });
  }

  /**
   * Add all species for regions which are preferred but don't have any species
   *
   * Note: Mutates "regionSpeciesMap"
   */
  private fillEmptyRegions(
    regionSpeciesMap: IEventRegionsAndSpecies,
    logbookSpeciesList: LogbookSpecies[],
    quotaSymbolRegionMap: QuotaSymbolRegionMap
  ): void {
    Object.entries(regionSpeciesMap).forEach(([region, speciesList]) => {
      if (!isEmpty(speciesList)) {
        return;
      }

      logbookSpeciesList?.forEach((species) => {
        if (!species.primary) {
          return;
        }

        if (
          isNil(species.quotaSymbols) &&
          equals(region, QuotaRegion.NonQuota)
        ) {
          regionSpeciesMap[QuotaRegion.NonQuota].push({
            ...species,
            quotaSymbol: '',
          });
        } else {
          species.quotaSymbols?.forEach((quotaSymbol) => {
            const speciesWithQuotaSymbol = { ...species, quotaSymbol };
            const speciesRegion = quotaSymbolRegionMap.get(quotaSymbol);

            if (equals(speciesRegion, region)) {
              speciesList.push(speciesWithQuotaSymbol);
            }
          });
        }
      });
    });
  }

  private retrievePendingSpecies(
    regionSpeciesMap: IEventRegionsAndSpecies,
    logbookSpeciesList: LogbookSpecies[]
  ): CatchPageSpecies[] {
    const pendingSpecies: CatchPageSpecies[] = [];
    // Populate the pending species list
    logbookSpeciesList?.forEach((species) => {
      if (isNil(species.quotaSymbols)) {
        const catchSpecies = { ...species, quotaSymbol: '' };
        if (!this.speciesExistsInMap(catchSpecies, regionSpeciesMap)) {
          pendingSpecies.push(catchSpecies);
        }
      } else {
        species.quotaSymbols?.forEach((quotaSymbol) => {
          const catchSpecies = { ...species, quotaSymbol };
          if (!this.speciesExistsInMap(catchSpecies, regionSpeciesMap)) {
            pendingSpecies.push(catchSpecies);
          }
        });
      }
    });

    return pendingSpecies;
  }

  /**
   * This function constructs the "regionSpeciesMap" and "pendingSpecies" for a given logbook based on the user's species and region preferences.
   *
   * The "regionSpeciesMap" is used to keep track the regions and species that will get displayed in the Logbook Catch page.
   *
   * The "pendingSpecies" is used to keep track of the species that were not selected by the preferences,
   * but are available to be added with the plus button on the top right.
   *
   * To avoid data structure change, "regionSpeciesMap" is only used internally to this
   *
   * If a species is added via the plus button, it's pulled out of the "pendingSpecies" and put into the "regionSpeciesMapping" (in the reducer).
   */
  public getEventRegionsAndSpecies(
    logbook: Logbook
  ): Observable<CatchPageSpecies[]> {
    if (isNil(logbook)) {
      return of(null);
    }
    return this.getQuotaSymbolRegionMap().pipe(
      withLatestFrom(
        this.preferenceRegionsSpeciesStore.select(
          selectFavoureRegionsAndSpeciesByLogbook(logbook.logbook)
        )
      ),
      map(([quotaSymbolRegionMap, preferredRegionsAndSpecies]) => {
        const regionSpeciesMap = this.applyPreferences(
          logbook,
          quotaSymbolRegionMap,
          preferredRegionsAndSpecies
        );

        // If NO region was preferred
        // add all species from all regions
        if (
          propEq(
            'length',
            0,
            Object.keys(preferredRegionsAndSpecies.quotaSpecies ?? {})
          ) &&
          propEq('length', 0, preferredRegionsAndSpecies.nonquotaSpecies)
        ) {
          this.addAllSpeciesFromAllRegions(
            regionSpeciesMap,
            logbook.species,
            quotaSymbolRegionMap
          );
        }

        this.fillEmptyRegions(
          regionSpeciesMap,
          logbook.species,
          quotaSymbolRegionMap
        );

        return this.retrievePendingSpecies(regionSpeciesMap, logbook.species);
      })
    );
  }
}
