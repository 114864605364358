import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import {
  hapticError,
  hapticSelect,
  hapticWarning,
} from '@qld-recreational/haptic';
import { networkConnected } from '@qld-recreational/network';
import { IAuthState } from '../../../../apps/commercial/src/app/auth/auth.reducer';
import { Store } from '@ngrx/store';
import { logout } from '../../../../apps/commercial/src/app/auth/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastController: ToastController,
    private store: Store<IAuthState>
  ) {}

  public async presentDebugModeToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 1000,
      position: 'top',
      color: 'dark',
    });
    await toast.present();
  }

  public async presentContentLostToast() {
    const toast = await this.toastController.create({
      message:
        '<ion-icon name="bulb-outline"></ion-icon> When you leave this page, any data not submitted will be lost',
      position: 'top',
      color: 'dark',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ],
    });
    hapticWarning();
    await toast.present();
  }

  public async presentSuccessToast(
    message = 'Your form has been submitted successfully',
    cssClass?: string,
    withOkButton = false
  ) {
    const toast = await this.toastController.create({
      message,
      position: 'top',
      color: 'success',
      ...(withOkButton
        ? {
            buttons: [
              {
                text: 'Ok',
                role: 'cancel',
              },
            ],
          }
        : { duration: 2000 }),

      cssClass,
    });
    hapticSelect();
    await toast.present();
  }

  public async presentInternetRequiredToastIfOffline() {
    const connected = await networkConnected();
    if (connected) {
      return true;
    }
    this.presentFailureToast(
      `Internet connection is required for this feature`
    );
    return false;
  }

  public async presentFailureToast(
    message = 'Something wrong with submission, please try again later'
  ) {
    const toast = await this.toastController.create({
      message,
      position: 'top',
      color: 'danger',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ],
    });
    hapticError();
    await toast.present();
  }

  public async presentFailureToastWithLogoutHandler(
    message = 'Something wrong with submission, please try again later',
    shouldLogout = false
  ) {
    const toast = await this.toastController.create({
      message,
      position: 'top',
      color: 'danger',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            if (!shouldLogout) {
              return;
            }
            this.store.dispatch(logout());
          },
        },
      ],
    });
    hapticError();
    await toast.present();
  }

  public async presentWarningToast(message: string) {
    const toast = await this.toastController.create({
      message,
      position: 'top',
      color: 'warning',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ],
    });
    hapticWarning();
    return toast.present();
  }

  public async presentOfflineToast(
    position: 'top' | 'bottom' | 'middle' = 'bottom'
  ) {
    const toast = await this.toastController.create({
      message: `You don't have internet connection. Switched to offline base map.`,
      position,
      duration: 5000,
      color: 'dark',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ],
    });
    hapticWarning();
    await toast.present();
  }

  public async presentOnlineToast(
    position: 'top' | 'bottom' | 'middle' = 'bottom'
  ) {
    const toast = await this.toastController.create({
      message: `Your internet is back.`,
      position,
      duration: 5000,
      color: 'dark',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ],
    });
    hapticWarning();
    await toast.present();
  }

  public async dismissToast() {
    await (await this.toastController.getTop())?.dismiss();
  }
}
